var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
//* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import { useStore } from 'react-context-hook';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { VideoPlayer } from '@app/components/atm.video-player';
import { HeaderV2 } from '@app/components/mol.header/header.component-v2';
import { TrackHeader } from '@app/components/mol.track-header';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { OuterDiv } from '@app/modules/questionnarie/questionnaire.styled';
import { AppPath } from '@app/routes';
import { Button, FlashMessageEnum, Separator, StyledContainer } from '@atomic';
import LogoFooter from '@atomic/obj.footer/LogoFooter';
import { Modal } from '@atomic/obj.modal';
import { TrackPhase } from '../track-phase';
import { TrackTask } from '../track-task/track-task.component';
import { Container, Line, TrackQuestions, LogoFooterBox, TrackWrapper } from './track-content.style';
var GET_COURSE_URL = function (courseCode) { return "curso/por-codigo/" + courseCode; };
var REACT_APP_ENDPOINT = process.env.REACT_APP_ENDPOINT;
export var TrackContent = function () {
    var _a;
    var courseCode = useParams().courseCode;
    var history = useHistory();
    var showFlashMessage = useFlashMessage()[0];
    var company = useStore('company')[0];
    var _b = React.useState(false), isOpenVideoModal = _b[0], setOpenVideoModal = _b[1];
    var _c = React.useState(null), videoUrl = _c[0], setVideoUrl = _c[1];
    var _d = React.useState(null), inFocusTaskId = _d[0], setInFocusTaskId = _d[1];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _e = useStore('currentCourse'), loadedCourse = _e[0], setLoadedCourse = _e[1];
    var _f = useStore('usuario'), usuario = _f[0], setUsuario = _f[1];
    var _g = React.useState([]), phasesCollapsed = _g[0], setPhasesCollapsed = _g[1];
    var RecuperarDadosDoUsuario = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.get('/usuario')];
                case 1:
                    response = _a.sent();
                    setUsuario(response.data.data);
                    return [2 /*return*/];
            }
        });
    }); };
    var _h = useMutation(function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoadedCourse(undefined);
                    return [4 /*yield*/, axiosClient.get(GET_COURSE_URL(courseCode))];
                case 1:
                    response = _a.sent();
                    setLoadedCourse(response.data.data);
                    scaleFirstUncompletedTask(response.data.data);
                    RecuperarDadosDoUsuario();
                    return [2 /*return*/, response.data.data];
            }
        });
    }); }), mutateCourse = _h.mutate, responseCourse = _h.data;
    React.useEffect(function () {
        if (!responseCourse) {
            mutateCourse();
        }
    }, [mutateCourse, responseCourse]);
    var handleOnPlayVideo = function (_videoUrl, isBlocked) {
        if (isBlocked === void 0) { isBlocked = false; }
        if (isBlocked)
            return;
        setOpenVideoModal(true);
        setVideoUrl(_videoUrl);
    };
    var refetchCertificate = function () {
        try {
            window.open(REACT_APP_ENDPOINT + ("api/v1/jogo/curso/" + courseCode + "/certificadoconclusao/usuario/" + usuario.id), '_blank', 'noreferrer');
        }
        catch (error) {
            showFlashMessage(FlashMessageEnum.alert, (error === null || error === void 0 ? void 0 : error.stack) || error);
        }
    };
    var icone = useQuery(['icones-track-on-page', company], function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get("icone/" + (company === null || company === void 0 ? void 0 : company.id))];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data.data];
                case 2:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }).data;
    var scaleFirstUncompletedTask = function (course) {
        var firstUncompletedFase = course.fases.find(function (fase) { return !fase.concluida; });
        if (firstUncompletedFase) {
            var firstUncompletedTask_1 = firstUncompletedFase.tarefas.find(function (task) { return !task.concluida; });
            if (firstUncompletedTask_1) {
                setTimeout(function () {
                    setInFocusTaskId(firstUncompletedTask_1.id);
                }, 100); // Aplica foco no elemento, para iniciar comportamento visual
                setTimeout(function () {
                    setInFocusTaskId(null);
                }, 600); // Retira o foco do elemento, para finalizar comportamento visual
            }
        }
    };
    var handleTrackPhaseClick = function (phase) {
        if (phasesCollapsed.includes(phase.id)) {
            setPhasesCollapsed(phasesCollapsed.filter(function (id) { return id !== phase.id; }));
        }
        else {
            setPhasesCollapsed(function (prev) { return __spreadArrays(prev, [phase.id]); });
        }
        if (phase.bloqueada) {
            scaleFirstUncompletedTask(loadedCourse);
        }
    };
    return (React.createElement(OuterDiv, { style: { padding: '0' } },
        React.createElement(StyledContainer, { primaryColor: true, className: 'use-inverse-colors' },
            React.createElement(HeaderV2, { companyLogoURL: company === null || company === void 0 ? void 0 : company.imgLogoEscuro, backButtonFn: function () { return history.push(AppPath.GroupCourse); } })),
        React.createElement(TrackHeader, null),
        React.createElement(Container, { style: { backgroundImage: "url(" + (company === null || company === void 0 ? void 0 : company.imgBackgroundTrilha) + ")", backgroundSize: 'cover' } },
            React.createElement(TrackQuestions, null, (_a = loadedCourse === null || loadedCourse === void 0 ? void 0 : loadedCourse.fases) === null || _a === void 0 ? void 0 :
                _a.map(function (phase, index) { return (React.createElement(React.Fragment, { key: phase.id },
                    React.createElement("div", { style: {
                            borderTop: '3px dashed rgba(73, 68, 60, 0.6)',
                            cursor: 'pointer',
                        } },
                        React.createElement(TrackPhase, { handleOnClick: function () { return handleTrackPhaseClick(phase); }, handleOnPlayVideo: handleOnPlayVideo, phaseNumber: index + 1, phase: phase, icone: icone === null || icone === void 0 ? void 0 : icone.filter(function (ico) { return ico === null || ico === void 0 ? void 0 : ico.isFase; })[0] }),
                        phasesCollapsed.includes(phase.id) && (React.createElement(React.Fragment, null,
                            React.createElement(TrackWrapper, null, phase.tarefas.map(function (task, idx) { return (React.createElement(TrackTask, { key: task.id, task: task, idx: idx, icone: icone === null || icone === void 0 ? void 0 : icone.filter(function (ico) { return ico === null || ico === void 0 ? void 0 : ico.isTarefa; })[0], isInFocus: inFocusTaskId === task.id, handleOnPlayVideo: handleOnPlayVideo, handleOnClick: task.bloqueada ? function () { return scaleFirstUncompletedTask(loadedCourse); } : undefined })); }))))))); }),
                React.createElement(Modal, { opened: isOpenVideoModal, onClose: function () { return setOpenVideoModal(false); } },
                    React.createElement(VideoPlayer, { videoUrl: videoUrl })),
                (loadedCourse === null || loadedCourse === void 0 ? void 0 : loadedCourse.concluida) && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { onClick: refetchCertificate, style: { textAlign: 'center' } }, "Baixar Certificado"),
                    React.createElement(Separator, null),
                    React.createElement(Button, { onClick: function () { return history.push(AppPath.Map); }, style: { textAlign: 'center' }, variant: "secondary" }, "Ir para o Mapa"))),
                React.createElement(Line, null),
                React.createElement(LogoFooterBox, null,
                    React.createElement(LogoFooter, null))))));
};
