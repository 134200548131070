var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useStore } from 'react-context-hook';
import { BsCheckLg } from 'react-icons/bs';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { RoundLogo } from '@app/components/atm.logo/logo.component';
import { RoundBox } from '@app/components/round-box/round-box';
import { axiosClient } from '@app/configure-app';
import { CenterModal } from '@app/modules/quiz/components/phase-completed/components/avaliation-modal/avaliation-modal.styled';
import { H1, H2 } from '@atomic';
export var Thanks = function (_a) {
    var logo = _a.logo;
    var company = useStore('company')[0];
    var _b = React.useState(''), image = _b[0], setImage = _b[1];
    var id = useParams().id;
    var GET_UNIDADE_EMPRESA_URL = "unidade-empresa/" + id;
    var unidadeEmpresa = useQuery('getUnidadeEmpresa', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, axiosClient.get(GET_UNIDADE_EMPRESA_URL)];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, (_a = response.data) === null || _a === void 0 ? void 0 : _a.data];
            }
        });
    }); }, {
        onSuccess: function (response) {
            if (response.logoUnidadeEmpresa) {
                setImage(response.logoUnidadeEmpresa);
            }
            else {
                setImage(company === null || company === void 0 ? void 0 : company.imgLogo);
            }
        },
    }).data;
    return (React.createElement(CenterModal, null,
        React.createElement(RoundLogo, { src: logo }),
        React.createElement(H1, { style: { fontWeight: 'bold', fontSize: 22 } }, "A sua opini\u00E3o \u00E9 importante para n\u00F3s!"),
        React.createElement(RoundBox, null,
            React.createElement(H2, { style: { fontWeight: 'bold', fontSize: 20 } }, "Sua avalia\u00E7\u00E3o foi enviada com sucesso!"),
            React.createElement(StyledIconWrapper, null,
                React.createElement(DashedCircle, null,
                    React.createElement(BsCheckLg, { size: 150, color: "#008425" }))),
            React.createElement(H2, { style: { fontWeight: 'bold', fontSize: 20 } }, "Agradecemos a sua opini\u00E3o!"))));
};
var StyledIconWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 40px;\n  margin-bottom: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 200px;\n  width: 100%;\n"], ["\n  margin-top: 40px;\n  margin-bottom: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 200px;\n  width: 100%;\n"])));
var DashedCircle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 200px;\n  height: 200px;\n  border: 3px dashed #008425;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  width: 200px;\n  height: 200px;\n  border: 3px dashed #008425;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2;
