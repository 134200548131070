var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useStore } from 'react-context-hook';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { ModalPdf } from '@app/components/modal-pdf';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { QuizContext } from '@app/providers/quiz.store';
import { AppPath } from '@app/routes';
import { FlashMessageEnum, FontFamily } from '@atomic';
import { TrackTaskModal } from './modal';
import { LabelBox, CircleContent, TaskWrapper, CircleIconBox, CheckIcon, LockIcon, CircleIcon, IconWrapper, AproveitamentoIcon, TaskContentWrapper, } from './track-task.styled';
export var TrackTask = function (_a) {
    var _b, _c;
    var task = _a.task, idx = _a.idx, isInFocus = _a.isInFocus, handleOnClick = _a.handleOnClick, icone = _a.icone, handleOnPlayVideo = _a.handleOnPlayVideo;
    var showFlashMessage = useFlashMessage()[0];
    var history = useHistory();
    var _d = useState(false), isOpenModal = _d[0], setOpenModal = _d[1];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _e = React.useContext(QuizContext), _1 = _e[0], __1 = _e[1], setQuestions = _e[2], setCurrentQuizzIndex = _e[3];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _f = useStore('taskSelected'), taskSelected = _f[0], setTaskSelected = _f[1];
    var loadedCourse = useStore('currentCourse')[0];
    var _g = useState(false), isBlocked = _g[0], setIsBlocked = _g[1];
    var _h = useState(false), isUpdated = _h[0], setIsUpdated = _h[1];
    var _j = useState(false), isCompleted = _j[0], setIsCompleted = _j[1];
    var _k = useState(false), hasIconBox = _k[0], setHasIconBox = _k[1];
    var _l = useState(false), isArquivoModalOpen = _l[0], setIsArquivoModalOpen = _l[1];
    var _m = useState(''), arquivoModalLink = _m[0], setArquivoModalLink = _m[1];
    var openArquivoModal = function (link) {
        setArquivoModalLink(link);
        setIsArquivoModalOpen(true);
    };
    var closeArquivoModal = function () {
        setArquivoModalLink('');
        setIsArquivoModalOpen(false);
    };
    var hasQuiz = ((_b = task.quizzes) === null || _b === void 0 ? void 0 : _b.length) > 0;
    var hasVideo = !!((_c = task === null || task === void 0 ? void 0 : task.video) === null || _c === void 0 ? void 0 : _c.url);
    var hasArquivo = !!task.arquivo;
    var hasHTMLInstructions = !!(task === null || task === void 0 ? void 0 : task.conteudoHtml);
    useEffect(function () {
        setIsBlocked(task === null || task === void 0 ? void 0 : task.bloqueada);
        setIsUpdated(task === null || task === void 0 ? void 0 : task.atualizada);
        setIsCompleted(task === null || task === void 0 ? void 0 : task.concluida);
        setHasIconBox(isCompleted || isBlocked || isUpdated);
    }, [task]);
    var userUrl = 'usuario';
    var _o = useQuery('user-on-track-page', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(userUrl)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }), user = _o.data, isLoading = _o.isLoading;
    var handleOkClicked = function () {
        if (!isLoading) {
            if (loadedCourse.podeGastarEnergiaJogador && user.data.energias < 1) {
                showFlashMessage(FlashMessageEnum.alert, 'Você não possui Energias. Compre na loja para voltar a jogar.');
                return;
            }
            if (task.concluida) {
                setOpenModal(true);
            }
            else {
                navigateToQuiz();
            }
        }
    };
    var navigateToQuiz = function () {
        setTaskSelected(idx);
        if (task.bloqueada)
            return;
        if (hasQuiz) {
            setCurrentQuizzIndex(0);
            setQuestions(task.quizzes);
            if (hasVideo || hasArquivo || hasHTMLInstructions) {
                history.push({
                    pathname: "" + AppPath.Learning,
                    state: { quiz: task, hideBackToQuizButton: false, redirectToQuizPage: true },
                });
            }
            else {
                history.push({ pathname: "" + AppPath.Quiz, state: { tarefa: task } });
            }
        }
        else {
            return;
        }
    };
    var TarefaImageIcon = function () { return (React.createElement("div", { style: { alignItems: 'center' }, className: "tw-flex tw-justify-center tw-mb-4  " + (isInFocus && ' transform: scale(1.35)') },
        React.createElement("div", null,
            hasIconBox && (React.createElement(IconWrapper, { isBlocked: isBlocked },
                isCompleted && !isBlocked && React.createElement(CheckIcon, null),
                isBlocked && React.createElement(LockIcon, null),
                isUpdated && React.createElement(CircleIcon, null))),
            task.aproveitamento > 0 && task.aproveitamento != 100 && (React.createElement(IconWrapper, null,
                React.createElement(AproveitamentoIcon, null,
                    task.aproveitamento,
                    "%")))),
        React.createElement("div", { className: "tw-mx-2 " + (isBlocked && 'tw-opacity-50') },
            React.createElement("img", { style: { maxWidth: '80px' }, src: icone === null || icone === void 0 ? void 0 : icone.link }),
            ' '))); };
    var TarefaCircle = function () { return (React.createElement(CircleContent, { isBlocked: isBlocked, isCompleted: isCompleted, isInFocus: isInFocus, style: isBlocked ? { borderColor: '#bdbdbd' } : null, withBorder: !(icone === null || icone === void 0 ? void 0 : icone.isTarefa) },
        React.createElement(CircleIconBox, null,
            hasIconBox ? (React.createElement(IconWrapper, { isBlocked: isBlocked },
                isCompleted && !isBlocked && React.createElement(CheckIcon, null),
                isBlocked && React.createElement(LockIcon, null),
                isUpdated && React.createElement(CircleIcon, null))) : null,
            React.createElement(IconWrapper, null,
                React.createElement(AproveitamentoIcon, null,
                    task.aproveitamento,
                    "%"))),
        React.createElement("span", { style: { textAlign: 'center' } }, (icone === null || icone === void 0 ? void 0 : icone.isTarefa) ? React.createElement("img", { src: icone === null || icone === void 0 ? void 0 : icone.link, style: { width: '75%', height: '75%' } }) : React.createElement(React.Fragment, null,
            "T.",
            idx + 1)))); };
    var handleOnTaskClick = function () {
        handleOkClicked();
        handleOnClick();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TaskWrapper, { className: "tw-w-1/3 " + (!isBlocked && 'tw-cursor-pointer') },
            React.createElement(TaskContentWrapper, { onClick: handleOnTaskClick },
                (icone === null || icone === void 0 ? void 0 : icone.isTarefa) ? React.createElement(TarefaImageIcon, null) : React.createElement(TarefaCircle, null),
                React.createElement(LabelBox, { className: isBlocked && 'tw-opacity-50' },
                    React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter700, fontSize: '14px', color: 'rgba(73, 68, 60, 1)' }, variant: "body1", component: "div" }, task.nome)))),
        isOpenModal && (React.createElement(TrackTaskModal, { button: navigateToQuiz, task: task, isOpenModal: isOpenModal, close: function () {
                setOpenModal(false);
            } })),
        isArquivoModalOpen && React.createElement(ModalPdf, { link: arquivoModalLink, onClose: closeArquivoModal })));
};
