var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useStore, withStore } from 'react-context-hook';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, BrowserRouter as Router } from 'react-router-dom';
import styled, { keyframes, ThemeProvider } from 'styled-components';
import Container from 'typedi';
import { MuteIcon } from '@app/components/atm.mute-icon/mute-icon.styled';
import { FlashWrapper } from '@app/components/obj.flash-wrapper';
import useThemeDarkProps from '@app/components/obj.theme/theme.props';
import { GlobalStore } from '@app/core/services';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { useFlashStore, useUserStore } from '@app/providers';
import { CommunicateListProvider } from '@app/providers/communicate-list.store';
import { GroupCourseListProvider } from '@app/providers/group-course-list.store';
import { QuestionnaireListProvider } from '@app/providers/questionnaire-list.store';
import { QuestionnaireProvider } from '@app/providers/questionnaire.store';
import { QuizProvider } from '@app/providers/quiz.store';
import { UnitCourseListProvider } from '@app/providers/unit-course-list.store';
import { Routes } from '@app/routes';
import { GlobalStyled } from '@atomic/obj.globals';
var initialState = { currentCourse: {} };
var fallbackPrimaryColor = '#003B82';
var ThemeUpdateContext = createContext(null);
var LightTheme = {
    darkMode: false,
    backgroundColor: '#fffcfb',
    backgroundItem: '#fff',
    itemColor: '#49443C',
    borderColor: '#D9D9D9',
};
var DarkTheme = {
    darkMode: true,
    backgroundColor: '#1E1F26',
    backgroundItem: '#32333E',
    itemColor: '#fff',
    borderColor: '#4F4F4F',
};
export var useThemeUpdate = function () {
    return useContext(ThemeUpdateContext);
};
function App() {
    var queryClient = new QueryClient();
    var authStorageService = Container.get(AuthStorageService);
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(false), isNotFound = _b[0], setIsNotFound = _b[1];
    var _c = useStore('domain'), domain = _c[0], setDomain = _c[1];
    var isMute = useStore('mute', false)[0];
    var _d = useState(), theme = _d[0], setTheme = _d[1];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _e = useStore('company'), company = _e[0], setCompany = _e[1];
    var isDarkMode = useThemeDarkProps();
    var updateTheme = useCallback(function (newTheme) {
        setTheme(function (prevTheme) {
            return __assign(__assign({}, prevTheme), newTheme);
        });
    }, [setTheme]);
    var getCurrentTheme = useCallback(function () {
        return isDarkMode ? DarkTheme : LightTheme;
    }, [isDarkMode]);
    useEffect(function () {
        if (theme !== undefined) {
            updateTheme(getCurrentTheme());
        }
    }, [updateTheme, getCurrentTheme]);
    var loadCompanyThemeIfExists = useCallback(function () {
        if (!domain)
            return;
        setIsLoading(true);
        var baseURL = process.env.REACT_APP_ENDPOINT;
        var findCompanyByDomainUrl = baseURL + "api/v1/jogo/empresa/by-name/" + domain;
        axios
            .create({ baseURL: baseURL })
            .get(findCompanyByDomainUrl)
            .then(function (_a) {
            var companyApiResponse = _a.data;
            updateTheme(__assign(__assign({}, getCurrentTheme()), { corPrimaria: companyApiResponse.data.corPrimaria || fallbackPrimaryColor, imgLogo: companyApiResponse.data.imgLogo, imgLogoEscuro: companyApiResponse.data.imgLogoEscuro, logoUnidadeEmpresa: companyApiResponse.data.logoUnidadeEmpresa }));
            setIsLoading(false);
            setIsNotFound(false);
            setCompany(companyApiResponse.data);
        })
            .catch(function () {
            setIsLoading(false);
            setIsNotFound(true);
        });
    }, [domain, updateTheme, getCurrentTheme, setCompany]);
    useEffect(function () {
        var previousDomain = localStorage.getItem('domain');
        var pathDomain = window.location.pathname.split('/')[1];
        if (!!previousDomain && previousDomain !== pathDomain) {
            authStorageService.logout();
            localStorage.removeItem('domain');
        }
        setDomain(pathDomain);
        localStorage.setItem('domain', pathDomain);
        if (!theme && !isNotFound) {
            loadCompanyThemeIfExists();
        }
    }, [setDomain, loadCompanyThemeIfExists, theme, isNotFound, authStorageService]);
    return (React.createElement(Router, null,
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(GlobalStore, { stores: [useUserStore, useFlashStore] },
                isLoading && (React.createElement(Overlay, null,
                    React.createElement(Spinner, null))),
                !isLoading && isNotFound && (React.createElement(React.Fragment, null,
                    React.createElement("h1", null, "Empresa N\u00E3o encontrada"),
                    React.createElement("h2", null, "Verifique a URL ou consulte o seu RH."))),
                theme && (React.createElement(ThemeUpdateContext.Provider, { value: updateTheme },
                    React.createElement(ThemeProvider, { theme: theme },
                        React.createElement(GlobalStyled, null),
                        React.createElement(FlashWrapper, null),
                        React.createElement(CommunicateListProvider, null,
                            React.createElement(GroupCourseListProvider, null,
                                React.createElement(UnitCourseListProvider, null,
                                    React.createElement(QuestionnaireListProvider, null,
                                        React.createElement(QuestionnaireProvider, null,
                                            React.createElement(QuizProvider, null,
                                                React.createElement(BrowserRouter, { basename: domain + "/" },
                                                    isMute && React.createElement(MuteIcon, { size: 25, color: "#fff" }),
                                                    React.createElement(Routes, null)))))))))))))));
}
export default withStore(App, initialState);
var overlayAnimation = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    from {\n        backdrop-filter: blur(0px);\n    }\n    to {\n        backdrop-filter: blur(10px); /* Adjust blur radius as needed */\n    }\n"], ["\n    from {\n        backdrop-filter: blur(0px);\n    }\n    to {\n        backdrop-filter: blur(10px); /* Adjust blur radius as needed */\n    }\n"])));
var spinAnimation = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n"], ["\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n"])));
var Overlay = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(255, 255, 255, 0.9); /* Adjust opacity as needed */\n  backdrop-filter: blur(0px);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 9999;\n  animation: ", " 0.5s ease-in-out forwards;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(255, 255, 255, 0.9); /* Adjust opacity as needed */\n  backdrop-filter: blur(0px);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 9999;\n  animation: ", " 0.5s ease-in-out forwards;\n"])), overlayAnimation);
var Spinner = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: 8px solid #f3f3f3;\n  border-top: 8px solid #3498db;\n  border-radius: 50%;\n  width: 50px;\n  height: 50px;\n  animation: ", " 1s linear infinite;\n"], ["\n  border: 8px solid #f3f3f3;\n  border-top: 8px solid #3498db;\n  border-radius: 50%;\n  width: 50px;\n  height: 50px;\n  animation: ", " 1s linear infinite;\n"])), spinAnimation);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
