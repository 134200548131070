var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { FaIcon } from '@atomic';
export var CheckIcon = styled(FaIcon.Check)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  // border: 1px solid ", ";\n  color: green;\n"], ["\n  // border: 1px solid ", ";\n  color: green;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var AproveitamentoIcon = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: red;\n  font-size: 0.6rem !important;\n"], ["\n  color: red;\n  font-size: 0.6rem !important;\n"])));
export var CircleContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  border-radius: 50%;\n  background: white;\n  width: 68px;\n  height: 68px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: center;\n  transition: all 0.5s ease;\n  ", "\n\n  span {\n    font-size: 20px;\n    font-weight: bold;\n  }\n  &:hover {\n    ", "\n  }\n"], ["\n  ", ";\n  border-radius: 50%;\n  background: white;\n  width: 68px;\n  height: 68px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  align-items: center;\n  justify-content: center;\n  transition: all 0.5s ease;\n  ", "\n\n  span {\n    font-size: 20px;\n    font-weight: bold;\n  }\n  &:hover {\n    ", "\n  }\n"])), function (_a) {
    var theme = _a.theme, withBorder = _a.withBorder;
    return (withBorder ? "border: 10px solid " + theme.corPrimaria + ";" : '');
}, function (props) { return (props.isInFocus ? ' transform: scale(1.35);' : null); }, function (props) { return (!props.isBlocked ? ' transform: scale(1.12); cursor: pointer' : null); });
export var CircleIconBox = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 68px;\n  position: absolute;\n"], ["\n  width: 68px;\n  position: absolute;\n"])));
export var CircleIcon = styled(FaIcon.Circle)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: orange;\n  background: orange;\n  border-radius: 50%;\n  border: 0.13rem solid white;\n"], ["\n  color: orange;\n  background: orange;\n  border-radius: 50%;\n  border: 0.13rem solid white;\n"])));
export var IconWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n  border-radius: 50%;\n  margin-left: -7px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n  ", ";\n\n  svg {\n    width: 0.9rem !important;\n  }\n"], ["\n  width: 24px;\n  height: 24px;\n  border-radius: 50%;\n  margin-left: -7px;\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n  ",
    ";\n\n  svg {\n    width: 0.9rem !important;\n  }\n"])), function (props) {
    return props.isBlocked ? "background: #bdbdbd;" : "background: white; border: 2px solid " + props.theme.corPrimaria;
});
export var LabelBox = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n  width: fit-content;\n  padding: 1px 7px 1px 7px;\n  margin-top: 12px;\n  border-bottom-left-radius: 8px;\n  border-bottom-right-radius: 8px;\n  border-top-right-radius: 8px;\n  border-top-left-radius: 8px;\n  border: 1px solid ", ";\n  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);\n  z-index: 2;\n  background: #fff;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: center;\n  align-items: center;\n  width: fit-content;\n  padding: 1px 7px 1px 7px;\n  margin-top: 12px;\n  border-bottom-left-radius: 8px;\n  border-bottom-right-radius: 8px;\n  border-top-right-radius: 8px;\n  border-top-left-radius: 8px;\n  border: 1px solid ", ";\n  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);\n  z-index: 2;\n  background: #fff;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var LockIcon = styled(FaIcon.Lock)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: white;\n  background: #bdbdbd;\n"], ["\n  color: white;\n  background: #bdbdbd;\n"])));
export var TaskWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: row;\n  justify-content: center;\n  width: 100%;\n  position: relative;\n  padding-bottom: 20px;\n  align-items: center;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: row;\n  justify-content: center;\n  width: 100%;\n  position: relative;\n  padding-bottom: 20px;\n  align-items: center;\n"])));
export var TaskContentWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: row;\n  align-items: center;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  flex-direction: row;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
