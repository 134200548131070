var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { IconButton } from '@mui/material';
import { ErrorMessage, Field, Formik } from 'formik';
import { useStore } from 'react-context-hook';
import { AiFillDelete } from 'react-icons/ai';
import { BsEmojiSmile } from 'react-icons/bs';
import { BsEmojiFrown } from 'react-icons/bs';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-styled-flexboxgrid';
import * as yup from 'yup';
import { RoundLogo } from '@app/components/atm.logo/logo.component';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { RoundBox } from '@app/components/round-box/round-box';
import { axiosClient } from '@app/configure-app';
import { GoogleBusinessRedirectModal } from '@app/modules/nps/avaliation/redirect-google-business';
import { ButtonAvaliar, CenterModal, CommentInput, CustomerInfo, } from '@app/modules/quiz/components/phase-completed/components/avaliation-modal/avaliation-modal.styled';
import { getUserLocation } from '@app/services/location';
// import { convertFileToBase64 } from '@app/utils/shared-functions.utils';
import { convertFileToBase64 } from '@app/utils/shared-functions.utils';
import { CheckboxField, FlashMessageEnum, H1, H2, TextField } from '@atomic';
import { Switch } from '@atomic/atm.switch';
import { marks } from './nps-marks';
import { CommentCounter, GradientSlider, StyledErrorMessageWrapper, StyledSliderWrapper, StyledSwitchLabel, StyledSwitchWrapper, } from './styles';
export var NPS = function () {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var commentMaxLength = 500;
    var _a = useStore('nps', false), _ = _a[0], setNps = _a[1];
    var company = useStore('company')[0];
    var id = useParams().id;
    var _b = React.useState(5), avaliacaoValue = _b[0], setAvaliacaoValue = _b[1];
    var _c = React.useState(true), isOpenComment = _c[0], setOpenComment = _c[1];
    var _d = React.useState(''), comment = _d[0], setComment = _d[1];
    var _e = React.useState(false), isUserInfoFilled = _e[0], setUserInfoFilled = _e[1];
    var _f = React.useState(false), realizouAvaliacao = _f[0], setRealizouAvaliacao = _f[1];
    var _g = React.useState(undefined), coordenadas = _g[0], setCoordenadas = _g[1];
    var _h = React.useState(undefined), userAgent = _h[0], setUserAgent = _h[1];
    var _j = React.useState(undefined), file = _j[0], setFile = _j[1];
    var _k = React.useState(null), fileBase64 = _k[0], setFileBase64 = _k[1];
    var _l = React.useState(true), lgpd = _l[0], setLgpd = _l[1];
    var _m = React.useState(false), openGoogleBusinessModal = _m[0], setOpenGoogleBusinessModal = _m[1];
    var showFlashMessage = useFlashMessage()[0];
    var _o = React.useState(''), image = _o[0], setImage = _o[1];
    var _p = React.useState(false), isLoading = _p[0], setIsLoading = _p[1];
    var handleImageChange = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setFile(URL.createObjectURL(event.target.files[0]));
                    _a = setFileBase64;
                    return [4 /*yield*/, convertFileToBase64(event.target.files[0])];
                case 1:
                    _a.apply(void 0, [_b.sent()]);
                    return [2 /*return*/];
            }
        });
    }); };
    var fileInputRef = React.useRef(null);
    var handleDeleteImageClick = function () {
        setFileBase64(null);
        setFile(undefined);
        fileInputRef.current.value = '';
    };
    React.useEffect(function () {
        setUserAgent(window.navigator.userAgent);
        var getLocation = function () { return __awaiter(void 0, void 0, void 0, function () {
            var userCoordenadas, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getUserLocation()];
                    case 1:
                        userCoordenadas = _a.sent();
                        setCoordenadas(userCoordenadas);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error fetching user location:', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        getLocation();
    }, []);
    var GET_UNIDADE_EMPRESA_URL = "unidade-empresa/" + id;
    var unidadeEmpresa = useQuery('getUnidadeEmpresa', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, axiosClient.get(GET_UNIDADE_EMPRESA_URL)];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, (_a = response.data) === null || _a === void 0 ? void 0 : _a.data];
            }
        });
    }); }, {
        onSuccess: function (response) {
            if (response.logoUnidadeEmpresa) {
                setImage(response.logoUnidadeEmpresa);
            }
            else {
                setImage(company === null || company === void 0 ? void 0 : company.imgLogo);
            }
        },
        onError: function (error) {
            showFlashMessage(FlashMessageEnum.alert, (error === null || error === void 0 ? void 0 : error.stack) || error);
        },
    }).data;
    var handleSubmit = function (formValues) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (isLoading)
                        return [2 /*return*/];
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, axiosClient.post("nps/" + id, {
                            nota: avaliacaoValue,
                            comentario: comment,
                            clienteNome: formValues.nome,
                            clienteEmail: formValues.email,
                            clienteTelefone: formValues.telefone,
                            lgpb: lgpd,
                            coordenadas: coordenadas,
                            userAgent: userAgent,
                            imagem: fileBase64,
                            unidadeEmpresaToken: localStorage.getItem('npsToken'),
                        })];
                case 2:
                    response = _a.sent();
                    localStorage.setItem('npsToken', unidadeEmpresa === null || unidadeEmpresa === void 0 ? void 0 : unidadeEmpresa.token);
                    if (avaliacaoValue >= 9 && (unidadeEmpresa === null || unidadeEmpresa === void 0 ? void 0 : unidadeEmpresa.urlGoogleBusiness)) {
                        setOpenGoogleBusinessModal(true);
                    }
                    else {
                        setNps(true);
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _a.sent();
                    console.error('Submission failed:', error_2);
                    localStorage.removeItem('npsToken');
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var closeGoogleBusinessModal = function () {
        setOpenGoogleBusinessModal(false);
        setNps(true);
    };
    var handleSliderChange = function (event, newValue) {
        setRealizouAvaliacao(true);
        setAvaliacaoValue(newValue);
        setOpenComment(true);
    };
    var schema = isUserInfoFilled
        ? yup.object({
            nome: yup.string().required('O nome é obrigatório'),
            email: yup.string().email('Email inválido').required('O email é obrigatório'),
            telefone: yup.string().required('O telefone é obrigatório'),
        })
        : yup.string();
    return (React.createElement(CenterModal, null,
        React.createElement(GoogleBusinessRedirectModal, { open: openGoogleBusinessModal, onClose: closeGoogleBusinessModal, urlGoogleBusiness: unidadeEmpresa === null || unidadeEmpresa === void 0 ? void 0 : unidadeEmpresa.urlGoogleBusiness }),
        React.createElement(RoundLogo, { src: image }),
        React.createElement(H1, { style: { fontWeight: 'bold', fontSize: 22 } }, "A sua opini\u00E3o \u00E9 importante para n\u00F3s!"),
        React.createElement(RoundBox, null,
            React.createElement(H2, null, "Como foi sua experi\u00EAncia conosco?"),
            React.createElement(StyledSliderWrapper, null,
                React.createElement(BsEmojiFrown, { style: { marginRight: '13px' }, color: "#A30000", size: 35 }),
                React.createElement(GradientSlider, { "aria-label": "Custom marks", onChange: handleSliderChange, value: avaliacaoValue, step: 1, valueLabelDisplay: "auto", marks: marks, min: 0, max: 10 }),
                React.createElement(BsEmojiSmile, { style: { marginLeft: '13px' }, color: "#008425", size: 35 })),
            realizouAvaliacao && (React.createElement("div", { style: { paddingLeft: '20px', paddingRight: '20px' } },
                React.createElement("p", { style: { textAlign: 'center' } }, "Deixe seu elogio, cr\u00EDtica ou coment\u00E1rio. Em que podemos melhorar?"),
                React.createElement(CommentInput, { placeholder: "Insira o texto aqui...", style: { borderColor: comment.length > commentMaxLength ? 'red' : '' }, onChange: function (e) {
                        var inputValue = e.target.value;
                        setComment(inputValue);
                    } }),
                React.createElement(CommentCounter, { style: { color: comment.length > commentMaxLength ? 'red' : '' } },
                    comment.length,
                    "/",
                    commentMaxLength))),
            React.createElement(StyledSwitchWrapper, null,
                React.createElement(Switch, { onChange: function () { return setUserInfoFilled(function (old) { return !old; }); }, checked: !isOpenComment }),
                React.createElement(StyledSwitchLabel, null, "Quero receber um retorno")),
            React.createElement(Formik, { initialValues: { nome: '', telefone: '', email: '' }, enableReinitialize: true, onSubmit: handleSubmit, validationSchema: schema }, function (_a) {
                var setFieldValue = _a.setFieldValue, errors = _a.errors;
                return (React.createElement(CustomerInfo, { style: {
                        marginTop: '20px',
                        padding: '0px 20px',
                        marginBottom: '20px',
                    } },
                    isUserInfoFilled && (React.createElement(React.Fragment, null,
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 12 },
                                React.createElement(Field, { type: "text", name: "nome", className: "theme-color-input", style: { width: '100%' }, placeholder: 'Nome' }),
                                React.createElement(StyledErrorMessageWrapper, null,
                                    React.createElement(ErrorMessage, { name: "nome" })))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 12 },
                                React.createElement(TextField, { placeholder: 'Telefone', variant: "cel-phone", name: "telefone", className: "theme-color-input", style: { width: '100%' }, onValueChange: function (value) {
                                        setFieldValue('telefone', value);
                                    } }),
                                React.createElement(StyledErrorMessageWrapper, null,
                                    React.createElement("div", null, errors.telefone)))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 12 },
                                React.createElement(Field, { type: "email", name: "email", className: "theme-color-input", style: { width: '100%' }, placeholder: 'Email' }),
                                React.createElement(StyledErrorMessageWrapper, null,
                                    React.createElement(ErrorMessage, { name: "email" })))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 12 },
                                React.createElement(CheckboxField, { id: "i_accept_check", checked: lgpd, onValueChange: function (checkboxId, newValue) {
                                        setLgpd(newValue);
                                    } },
                                    React.createElement("a", { href: "https://docs.google.com/document/d/1WwcGwRo3MKRs3BN5bsddaGdnaasmqa8QIrMiJIiisKs/edit?usp=drivesdk", target: "_blank", rel: "noopener noreferrer" },
                                        React.createElement("span", { style: { fontSize: '14px', color: '#0078d4', fontWeight: 'bold' } }, "Termo de Consentimento para Coleta e Uso de Dados Pessoais"))))))),
                    React.createElement(Row, { style: { justifyContent: 'center' } }, "Caso sinta a necessidade, por favor envie uma imagem."),
                    React.createElement(Row, { style: { justifyContent: 'center', position: 'relative' } },
                        React.createElement("input", { ref: fileInputRef, type: "file", onChange: handleImageChange }),
                        React.createElement("img", { src: file }),
                        file && (React.createElement(IconButton, { style: {
                                color: '#A30000',
                                position: 'absolute',
                                right: '5px',
                                top: '40px',
                                zIndex: 2,
                                fontSize: '20px',
                            }, onClick: function () { return handleDeleteImageClick(); } },
                            React.createElement(AiFillDelete, null)))),
                    React.createElement(Row, { style: { justifyContent: 'center' } },
                        React.createElement(ButtonAvaliar, { type: "submit", disabled: isLoading || !realizouAvaliacao || comment.length > commentMaxLength }, isLoading ? 'Enviando...' : 'Enviar minha opinião'))));
            }))));
};
